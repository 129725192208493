/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  
  //desktop menu
  $('#menu-desktop-header-menu .menu-item-has-children > a').click( function(e){
	    e.preventDefault();
	    $this = $(this).parent();
	    $('#mega-menu').stop().slideUp('fast');
	    $('.course-menu-click').removeClass('mega-active');
	    $(this).parent().toggleClass('menu-link-active');
	    $('.sub-menu', $this).stop().slideToggle('fast');	      
	  });
	  
  $('.course-menu-click').click(function(e){
	  e.preventDefault();
	  $(this).toggleClass('mega-active');
	  $('#menu-desktop-header-menu .menu-item-has-children').removeClass('menu-link-active');
	  $('.sub-menu').stop().slideUp('fast');
	  $('#mega-menu').stop().slideToggle('fast');
	  });
  //close menu on click
  $('.cal-link').click(function(){
	  $('#mega-menu').stop().slideUp('fast');
	  $('.course-menu-click').removeClass('mega-active');
	  });
	  
  //mobile menu
  $('#mobile-button').click( function(){
	  $('#mob-drop').slideToggle('fast');
	  });
	  
  //Header slider
  $(document).ready(function(){
	  if($('#header-slider').length)
	    {
			$('#header-slider').bxSlider({
			  mode: 'fade',
			  captions: false,
			  auto: true,
              autoControls: false,
              pager: false,
              prevText: '<i class="fa fa-angle-left" aria-hidden="true"></i>',
              nextText: '<i class="fa fa-angle-right" aria-hidden="true"></i>',
              onSliderLoad: function(){
	                                   $('#header-slider-holder').css('visibility', 'visible');
	                                   $('#header-slider-holder .bx-controls.bx-has-controls-direction').addClass('container');
	                                   } 
			});		    
		}
	  });
//team slider
  $(document).ready(function(){
	  if($('.team-slider').length)
	    {
			$('.team-slider').bxSlider({
			  mode: 'fade',
			  captions: false,
			  auto: true,
              autoControls: false,
              pager: false,
              prevText: '<i class="fa fa-angle-left" aria-hidden="true"></i>',
              nextText: '<i class="fa fa-angle-right" aria-hidden="true"></i>',
              onSliderLoad: function(){
	                                   $('.team-slider-holder').css('visibility', 'visible');
	                                   $('.team-slider-holder .bx-controls.bx-has-controls-direction').addClass('container');
	                                   } 
			});		    
		}
	  });	

//carousel
  $(document).ready(function(){
	  if($('.carousel').length)
	    {
			$('.carousel').bxSlider({
			  slideWidth: 400,
			  minSlides: 1,
			  maxSlides: 3,
			  slideMargin: 10,
			  captions: false,
			  auto: true,
              autoControls: false,
              pager: false,
              shrinkItems: true,
              infiniteLoop: true,
              moveSlides: 1,
              prevText: '<i class="fa fa-angle-left" aria-hidden="true"></i>',
              nextText: '<i class="fa fa-angle-right" aria-hidden="true"></i>',
              onSliderLoad: function(){
	                                   $('.car-hold').css('visibility', 'visible');
	                                   $('.car-hold .bx-controls.bx-has-controls-direction').addClass('container');
	                                   } 
			});		    
		}
	  });
	    
  //calendar
	
		$(window).load( function() {
			var calEvents = '';
			$.ajax({
				url: "/wp-content/themes/taste-the-wild/ajax/ajax-calendar.php", 
				dataType : 'json',
				success: function(res){
					     //console.log(res);
                         calEvents = res;
 
 			$('#events-calendar').monthly({
				mode: 'event',
				dataType: 'json',
				events: calEvents,
				linkCalendarToEventUrl: true
			});
                         
                         }
                   });

		});
		
    //testimonials
    $(window).load( function() {
	    $('.filter-test').click(function(e){
		    e.preventDefault();
		    $('#screen').fadeIn('fast');
		    $('.filter-test').removeClass('active');
		    $(this).addClass('active');
		    $.ajax({
			    url: "/wp-content/themes/taste-the-wild/ajax/ajax-testimonials.php", 
				type : 'post',
				data: {
					 filter: $(this).data('id')
					},
				success: function(res){
					if(res != 'failed') 
					  {
						$('#testimonials').html(res);  
					  }
					 
					}//end success
			    }).done(function(){
				    $('#screen').fadeOut('fast');  
				    });//end ajax
		    });//end click
	    });
	    
	    //faqs
	    $('.single-faq .open-faq').click(function(){
		    $(this).next('.the-answer').slideDown('fast');
		    $(this).slideUp('fast');
		    });
		    
	    $('.single-faq .close-faq').click(function(){
		    $this = $(this).parent().parent();
		    //console.log($this);
		    $(this).parent().slideUp('fast');
		    $('.open-faq', $this).slideDown('fast');
		    });	
		    
		//load more posts
		$('#load-more').click(function(e){
			e.preventDefault();
			$('#screen').fadeIn('fast');
			var page = $('#paged').val();
			page = parseInt(page);
			page = page + 1;
			var cat = $('#cati').val();
			$('#paged').val(page);
		    $.ajax({
			    url: "/wp-content/themes/taste-the-wild/ajax/ajax-posts.php", 
				type : 'post',
				data: {
					 paged: page,
					 cati: cat
					},
				success: function(res){
					res = res.trim();
					if(res != 'failed') 
					  {
						$('#loop').append(res);  
					  }
				  else{
						$('#load-more').css('display', 'none');
					  }
					}//end success
			    }).done(function(){
				    $('#screen').fadeOut('fast');  
				    });//end ajax			
			});	
	    //fixed header
	    $(document).ready(function(){
            if($('body').scrollTop() > 1)
		      {
			    $('body').addClass("fixed-header");  
			  }
		  else{
				$('body').removeClass("fixed-header"); 
			  }
			      
			      
		    $(window).on('scroll', function(){
			    if($('body').scrollTop() > 1)
			      {
				    $('body').addClass("fixed-header");  
				  }
			  else{
					$('body').removeClass("fixed-header"); 
				  }
			    
			    });
		    });
		    
	    
})(jQuery); // Fully reference jQuery after this point.
